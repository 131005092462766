import Link from "next/link";

import NewLayout from "@components/new-ui/Layout";
import { GhostPostsOrPages, GhostSettings } from "@lib/ghost";
import { get, getLang } from "@utils/use-lang";
import React from "react";

interface Custom404Props {
    posts?: GhostPostsOrPages;
    settings?: GhostSettings;
    bodyClass?: string;
}

const Custom404: React.FC<Custom404Props> = () => {
    const text = get(getLang());

    return (
        <NewLayout title="Gallabox" description="404" canonical="https://gallabox.com/404">
            <section className="not-found-page">
                <div className="inner">
                    <section className="error-message">
                        <h1 className="error-code">404</h1>
                        <p className="error-description">{text("PAGE_NOT_FOUND")}</p>
                        <p className="error-description">
                            The page you were looking for could not be found. <br /> It might have been removed, renamed, or did not exist in the first place
                        </p>
                        <Link href="/">
                            <a className="error-link">{text("GOTO_FRONT_PAGE")} →</a>
                        </Link>
                    </section>
                </div>
            </section>
        </NewLayout>
    );
};

export default Custom404;
